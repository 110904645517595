import React from "react";
import Layout from "../../components/Layout";
import { StoreContext } from "../../context/store-context";
import LineItem from "../../components/LineItem";
import { formatPrice } from "../../utils/formatPrice";
import Loader from "../../components/Loader";
import { gql, useMutation } from "@apollo/client";
import { getUser } from "../../components/Auth";

const ASSOCIATE_CUSTOMER = gql`
  mutation checkoutCustomerAssociateV2(
    $checkoutId: ID!
    $customerAccessToken: String!
  ) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
      customer {
        id
      }
    }
  }
`;

const Cart = () => {
  const { checkout, loading } = React.useContext(StoreContext);
  const token = getUser().token;
  const emptyCart = checkout?.lineItems?.edges?.length;
 // console.log(checkout.lineItems.edges)

  const handleCheckout = () => {
    if (!token) {
      window.location.assign("/account");
    } else {
      associateCustomer({
        variables: {
          checkoutId: checkout.id,
          customerAccessToken: token,
        },
        onCompleted: () => {

          window.location.assign(checkout.webUrl)
      
        },
        onError: (error) => console.log(error.message),
      });
    }
  };
  const [associateCustomer] = useMutation(ASSOCIATE_CUSTOMER);

  return (
    <Layout>
      <div className="container mx-auto">
        <h1 className="lg:text-5xl px-4  font-bold leading-tight text-3xl my-8">
          Shopping cart
        </h1>

        <div className="col-12">
          {checkout.ready === undefined ? (
            <Loader />
          ) : emptyCart > 0 ? (
            <div>
              

              <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        <span className="sr-only">Image</span>
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Product
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Qty
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Price
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkout.lineItems.edges.map((item) => (
                 
     

                       <LineItem item={item.node} key={item.node.title}  />
                       
                     
              
                    ))}
                   
                  </tbody>
                </table>
              </div>
              <div className="text-end px-4 text-uppercase mt-6 mb-2 font-bold text-xl">
                Total Amount:{" "}
                {formatPrice(
                      checkout.totalPriceV2.currencyCode,
                      checkout.totalPriceV2.amount
                    )}
              </div>
      
              <div className="py-5 px-4 flex flex-col items-center">
           

                <button
                  disabled={loading}
                  onClick={handleCheckout}
                  className="bg-indigo-500 mb-4 max-w-sm font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full"
                >
                  Checkout as a guest
                </button>
                <button
                  disabled={loading}
                  className="bg-blue-700 max-w-sm font-semibold hover:bg-blue-800 py-3 text-sm text-white uppercase w-full"
                >
                  Checkout as a member
                </button>
              </div>
            </div>
          ) : (
            <div className="px-4">Cart is empty</div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Cart;
