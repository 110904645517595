import React, {useContext} from "react";
import { StoreContext } from "../context/store-context";
import { formatPrice } from "../utils/formatPrice";
import { GatsbyImage } from "gatsby-plugin-image";
import { getShopifyImage } from "gatsby-source-shopify";
import debounce from "lodash.debounce"

const LineItem = ({ item }) => {
  const { removeLineItem, checkout, updateLineItem } =
    useContext(StoreContext);
  const [quantity, setQuantity] = React.useState(item.quantity);
  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  };
  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  );

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])

  const handleQuantityChange = (value) => {
    if (value !== "" && Number(value) < 1) {
      return
    }
    setQuantity(value)
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 160,
        height: 160,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  );

  return (
    <tr className="bg-white border-b hover:bg-gray-50">
      <td className="p-4 w-32 min-w-[100px]">
        {image && (
          <GatsbyImage
            key={variantImage.src}
            image={image}
            alt={variantImage.altText ?? item.variant.title}
          />
        )}
      </td>
      <td className="py-4 px-6 font-semibold text-gray-900">
        <h4>
          {item.title} - {item.variant.title}
        </h4>
      </td>
      <td className="py-4 px-6">
        <div className="flex items-center mt-4">
          <button
            onClick={(e) => {
              e.preventDefault();
              doDecrement();
            }}
            className="inline-flex items-center p-1 text-sm font-medium text-gray-500 bg-white rounded-full border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200"
            type="button"
          >
            <span className="sr-only">Quantity button</span>
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <div>
            <input
              onChange={(e) => setQuantity(e.currentTarget.value)}
              value={quantity}
              type="number"
              onInput={(e) => (e.target.value = e.target.value.slice(0, 3))}
              className="bg-gray-50 w-14 border mx-2 border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1"
              required
            />
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              doIncrement();
            }}
            className="inline-flex items-center p-1 text-sm font-medium text-gray-500 bg-white rounded-full border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200"
            type="button"
          >
            <span className="sr-only">Quantity button</span>
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </td>
      <td className="py-4 px-6 font-semibold text-gray-900">
        {subtotal !== undefined ? subtotal : ""}
      </td>
      <td className="py-4 px-6">
        <button
          onClick={handleRemove}
          className="font-medium text-red-600 hover:underline"
        >
          Remove
        </button>
      </td>
    </tr>
  );
};
export default LineItem;
